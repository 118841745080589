.savednews-newscards {
  display: flex;
  flex-direction: column;
  background-color: #f5f6f7;
  padding: 62px 102px;
}

.savednews-newscards__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

@media screen and (max-width: 768px) {
  .savednews-newscards {
    padding: 32px 40px;
  }

  .savednews-newscards__container {
    gap: 8px;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .savednews-newscards {
    padding: 24px 16px 12px;
  }

  .savednews-newscards__container {
    gap: 10px;
  }
}
