.mobile {
  position: fixed;
  top: 56px;
  right: 0;
  left: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.mobile__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #1a1b22;
  padding: 16px 16px 24px;
}

.mobile__links {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.mobile__link {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 3;
  color: white;
  text-decoration: none;
  max-width: 288px;
}

.mobile__button {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
  border-radius: 100px;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  width: 90%;
  padding: 16px 0;
  margin-top: 22px;
  cursor: pointer;
}

.mobile__button-loggedin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  border: 1px solid #fff;
  background-color: transparent;
  color: #1a1b22;
  padding: 16px 15px;
  width: 288px;
  margin-top: 22px;
  cursor: pointer;
}

.mobile__username-loggedin {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
  color: #fff;
}

.mobile__logout {
  width: 24px;
  height: 24px;
  margin-left: 15px;
}

.mobile__content-savednews {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  padding: 16px 16px 24px;
}

.mobile__link-savednews {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 3;
  color: #1a1b22;
  text-decoration: none;
  max-width: 288px;
}

.mobile__button-savednews {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  border: 1px solid #1a1b22;
  background-color: transparent;
  padding: 16px 15px;
  width: 90%;
  margin-top: 22px;
}

.mobile__button-savednews:hover {
  cursor: pointer;
}

.mobile__username-savednews {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
  color: #1a1b22;
}
