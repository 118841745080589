.saved-news {
  padding: 40px 104px 56px;
  background-color: white;
}

.saved-news__container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.saved-news__title {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(26, 27, 34, 0.5);
}

.saved-news__header {
  margin: 0;
  font-family: "Roboto-Slab", Arial, sans-serif;
  font-size: 40px;
  line-height: 46px;
  font-weight: 400;
  color: rgba(26, 27, 34, 1);
}

.saved-news__keywords-container {
  display: flex;
  align-items: center;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: rgba(26, 27, 34, 1);
  height: 24px;
}

.saved-news__keywords-title {
  font-weight: 400;
}

.saved-news__keywords {
  margin-left: 3px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .saved-news {
    padding: 24px 40px 32px;
  }

  .saved-news__container {
    gap: 16px;
  }

  .saved-news__title {
    font-size: 14px;
  }

  .saved-news__header {
    font-size: 30px;
    line-height: 34px;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .saved-news {
    padding: 8px 16px 32px;
  }

  .saved-news__header {
    margin-bottom: 34px;
  }

  .saved-news__keywords-title {
    margin: 0;
  }
}
