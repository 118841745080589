.newscards__section {
  display: flex;
  flex-direction: column;
  background-color: #f5f6f7;
  padding: 80px 104px;
}

.newscards__title {
  margin: 0;
  font-family: "Roboto-Slab", Arial, sans-serif;
  font-size: 40px;
  line-height: 46px;
  font-weight: 400;
  color: black;
}

.newscards__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  margin: 64px 0;
}

.newscards__button {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  height: 64px;
  width: 288px;
  border-radius: 64px;
  background-color: white;
  margin: 0 auto;
}

.newscards__button:hover {
  background-color: #e8e8e8;
}

.newscards__button_hidden {
  display: none;
}

@media screen and (max-width: 875px) {
  .newscards__section {
    min-height: 200px;
    padding: 40px;
  }

  .newscards__title {
    font-size: 30px;
    line-height: 34px;
  }

  .newscards__container {
    gap: 8px;
    margin: 32px 0;
  }

  .newscards__button {
    height: 56px;
    width: 240px;
    border-radius: 56px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .newscards__section {
    padding: 32px 16px 24px;
  }

  .newscards__container {
    gap: 10px;
    margin: 58px 0 16px;
  }

  .newscards__button {
    width: 100%;
  }
}
