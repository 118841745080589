.card__container {
  background-color: white;
  border-radius: 25px;
  overflow: hidden;
  width: 400px;
  min-height: 576px;
  position: relative;
}

.card__keyword {
  background-color: white;
  padding: 8px 22px;
  text-align: center;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
  color: black;
  border-radius: 10px;
  position: absolute;
  top: 24px;
  left: 24px;
}

.card__popup-text {
  background-color: white;
  padding: 8px 22px;
  border-radius: 8px;
  text-align: center;
  position: absolute;
  top: 24px;
  right: 69px;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
  text-align: center;
}

.card__popup-text_hidden {
  display: none;
}

.card__button-delete {
  background: url(../../images/card-delete.svg);
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
}

.card__button-delete:hover {
  background: url(../../images/card-delete-hover.svg);
  cursor: pointer;
}

.card__button-bookmark {
  background: transparent url(../../images/bookmark-normal.svg);
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
}

.card__button-bookmark:hover {
  background: transparent url(../../images/bookmark-hover.svg);
}

.card__button-bookmark_marked {
  background: transparent url(../../images/bookmark-clicked.svg);
}

.card__image {
  width: 400px;
  height: 272px;
}

.card__text {
  text-align: left;
  margin: 0 24px;
}

.card__date-published {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #b6bcbf;
  margin-top: 17px;
}

.card__title {
  font-family: "Roboto-Slab", Arial, sans-serif;
  font-size: 26px;
  line-height: 30px;
  font-weight: 400;
  color: black;
  margin-top: 15px;
  height: 60px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card__content {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: black;
  margin-top: 16px;
  height: 6.9em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.card__source {
  font-family: "Roboto-Slab", Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #b6bcbf;
}

@media screen and (max-width: 1023px) {
  .card__container {
    border-radius: 14px;
    width: 224px;
    min-height: 420px;
  }

  .card__button-bookmark {
    top: 8px;
    right: 8px;
  }

  .card__image {
    width: 224px;
    height: 150px;
  }

  .card__text {
    text-align: left;
    margin: 16px;
  }

  .card__date-published {
    margin: 0 0 10px;
  }

  .card__title {
    font-size: 22px;
    line-height: 24px;
    margin: 0;
    height: 72px;
    -webkit-line-clamp: 3;
  }

  .card__content {
    margin: 10px 0 12px;
    height: 5.5em;
    overflow: hidden;
    -webkit-line-clamp: 4;
  }

  .card__source {
    bottom: -4px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

@media screen and (min-width: 320px) and (max-width: 535px) {
  .card__container {
    width: 100%;
  }

  .card__button-bookmark {
    top: 16px;
    right: 16px;
  }

  .card__image {
    width: 100%;
    height: 196px;
  }

  .card__title {
    height: 48px;
    -webkit-line-clamp: 2;
  }

  .card__content {
    margin: 14px 0 8px;
    height: 5.5em;
    overflow: hidden;
    -webkit-line-clamp: 4;
  }

  .card__source {
    bottom: -4px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .card__text {
    margin: 0 16px 16px;
  }

  .card__date-published {
    margin-top: 16px;
  }

  .card__source {
    margin: 0;
  }
}
