.preloader {
  /* min-height: 280px; */
  background-color: #f5f6f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
}

.circle-preloader {
  display: block;
  width: 74px;
  height: 74px;
  border: 1px solid #444;
  border-bottom-color: #888;

  border-radius: 50%;

  animation: spin 0.75s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.preloader__text {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #b6bcbf;
  text-align: center;
  margin: 24px 0 0 0;
}
