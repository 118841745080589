.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 104px;
  background-color: white;
}

.footer__text {
  margin: 0;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #b6bcbf;
}

.footer__links {
  display: flex;
  gap: 40px;
}

.footer__buttons {
  display: flex;
  gap: 40px;
}

.footer__button {
  background-color: transparent;
  color: black;
  border: none;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
}

.footer__icons {
  display: flex;
  gap: 24px;
}

.footer__icon-button {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 24px 40px 18px;
  }
}

@media screen and (min-width: 320px) and (max-width: 466px) {
  .footer {
    padding: 20px 16px 22px;
    flex-direction: column-reverse;
  }

  .footer__links {
    width: 100%;
    justify-content: space-between;
  }

  .footer__buttons {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .footer__text {
    margin: 40px 0 0 0;
  }
}
