.searchform {
  margin: 80px auto;
  display: flex;
  /* flex-direction: column;
  align-items: center; */
}

.searchform__container {
  height: 336px;
  width: 608px;
}

.searchform__text-heading {
  font-family: "Roboto-Slab", Arial, sans-serif;
  font-size: 60px;
  line-height: 64px;
  font-weight: 400;
  color: white;
  margin: 0 0 32px 0;
}

.searchform__text-subheading {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: white;
  margin: 0 0 88px 0;
}

.searchform__searchbar {
  width: 100%;
  height: 64px;
}

.searchform__searchbar-input {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  width: 608px;
  height: 64px;
  border-radius: 64px;
  border: 1px solid #d1d2d6;
  padding: 0 0 0 24px;
  box-sizing: border-box;
}

.searchform__searchbar-input::placeholder {
  color: #b6bcbf;
}

.searchform__invalid {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 400;
  color: #ff0000;
  margin-left: 20px;
}

.searchform__searchbar-button {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: white;
  background-color: #2f71e5;
  border: none;
  width: 168px;
  height: 64px;
  padding: 21px 56px;
  border-radius: 64px;
  position: relative;
  top: -64px;
  left: calc(75% + -16px);
  cursor: pointer;
}

.searchform__searchbar-button:hover {
  background-color: #347eff;
}

.searchform__searchbar-button:active {
  background-color: #2a65cc;
}

@media screen and (max-width: 768px) {
  .searchform {
    margin: 38px auto 48px;
  }

  .searchform__container {
    height: 248px;
    width: 452px;
  }

  .searchform__text-heading {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 16px;
  }

  .searchform__text-subheading {
    margin-bottom: 48px;
  }

  .searchform__searchbar-input {
    width: 448px;
    height: 56px;
    padding: 17px 24px;
    border-radius: 56px;
  }

  .searchform__searchbar-button {
    width: 160px;
    height: 56px;
    padding: 16px 52px;
    top: -64px;
    left: calc(75% + -51px);
  }
}

@media screen and (min-width: 320px) and (max-width: 488px) {
  .searchform {
    margin: 30px auto 32px;
    min-height: 418px;
  }

  .searchform__container {
    width: 288px;
  }

  .searchform__text-subheading {
    margin-bottom: 122px;
  }

  .searchform__searchbar-input {
    width: 288px;
    height: 56px;
    margin-bottom: 16px;
    padding: 17px 16px;
  }

  .searchform__invalid {
    position: relative;
    bottom: calc(147% + 16px);
    left: -3px;
  }

  .searchform__searchbar-button {
    position: relative;
    top: 8px;
    left: 0px;
    width: 288px;
    height: 56px;
  }
}
